import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Articles from "../components/articles";

const JournalPage = ({ data }) => {
  const articles = data.articles.nodes;

  return (
    <Layout>
      <Seo title="Journal" />
      <Hero title="Journal" />
      <Articles articles={articles} />
    </Layout>
  );
};

export default JournalPage;

export const query = graphql`
  query ($locale: String!) {
    articles: allContentfulArticle(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: publishedAt, order: DESC }
    ) {
      nodes {
        contentful_id
        publishedAt
        title
        slug
        featuredImage {
          description
          title
          gatsbyImageData(layout: FULL_WIDTH)
        }
        publishedAt
        excerpt {
          excerpt
        }
      }
    }

    translations: contentfulTranslations(node_locale: { eq: $locale }) {
      translations {
        key
        value
      }
    }
  }
`;
